<template>
  <v-container>
    <div class="pa-2">
      <h1 class="primary--text">Statut</h1>
      <v-simple-table v-if="statut_fetch_status === 'LOADED'">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Nom du statut
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="statut in statuts"
              :key="statut.id"
          >
            <td>{{ statut.name }}</td>
            <td>
              <v-btn @click="deleteStatut(statut)" class="white--text" color="red"><v-icon>mdi-trash-can</v-icon>Supprimer</v-btn>
              <EditStatut :statut="statut" @reload="$store.dispatch('dossier/statuts/getStatuts')"/>
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field v-model="statutModel.name" :rules="[value => value !== '' && /^\d+$/.test(value[0]) || 'Ne doit pas être vide et doit commencer par un nombre.']"></v-text-field>
            </td>
            <td>
              <v-btn color="primary" @click="createStatut">Créer</v-btn>
            </td>

          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row v-else>
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import EditStatut from "@/views/EditStatut";

export default {
  name: "Statuts",
  components: {EditStatut},
  async mounted() {
    let statusFetchStatus = this.$store.getters["dossier/statuts/statut_fetch_status"];
    if (statusFetchStatus === "INITIAL" || statusFetchStatus === "ERROR") {
      await this.$store.dispatch("dossier/statuts/getStatuts");
    }
  },
  data() {
    return {
      statutModel: {
        name: null,
      },
    }
  },
  computed: {
    statut_fetch_status() {
      return this.$store.getters["dossier/statuts/statut_fetch_status"];
    },
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"]
    }
  },
  methods: {
    async createStatut() {
      if (this.statutModel.name === null) return;
      let result = await this.$store.dispatch("dossier/statuts/postStatut", this.statutModel);
      if (result !== undefined && result !== null) {
        this.$store.dispatch("annonce/annonceSuccess", "Statut créé");
        this.$store.dispatch("dossier/statuts/getStatuts");
        this.statutModel.name = null;
      }
    },
    async deleteStatut(statut) {
      let result = await this.$store.dispatch("dossier/statuts/deleteStatut", statut);
      if(result === "success") {
         this.$store.dispatch("dossier/statuts/getStatuts");
        this.$store.dispatch("annonce/annonceSuccess", "Statut supprimé");
      }
    }
  }

}
</script>

