<template>
  <v-dialog max-width="600" v-model="dialog">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" v-on="on" v-bind="attrs" class="white--text">
        <v-icon>mdi-pencil</v-icon>
        Modifier
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field v-model="statutModel.name" label="name"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="editing" :color="editing ? 'grey': 'primary'" @click="patchStatut" class="white--text">Modifier</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="editing"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'EditStatut',
  props: {
    statut: Object
  },
  data() {
    return {
      statutModel: JSON.parse(JSON.stringify(this.statut)),
      dialog: false,
      editing: false,
    }
  },
  methods: {
    async patchStatut() {
      this.editing = true;
      await this.$store.dispatch("dossier/statuts/patchStatut", this.statutModel);
      this.editing = false;
      this.dialog = false;
      this.$store.dispatch("annonce/annonceSuccess", "Statut modifié");
      this.$emit("reload");
    }
  }

}
</script>
